nav {
    padding: 1rem;
}

.navbar {
    list-style: none;
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    padding-left: 1rem;
}

.navbar .title a {
    font-size: 2rem;
    font-weight: 700;
    text-decoration: none;
    color: black;
}

.navbar .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
}

.navbar .items a {
    text-decoration: none;
    font-size: 1rem;
    color: black;
}
