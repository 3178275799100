.app {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Full viewport height */
}

main {
  padding: 0 2rem 0 2rem;
  margin-bottom: 3rem;
  flex: 1; /* Take up remaining space */
}
