.home {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding-top: 2%;
}

.alekna {
    color: #68a201;
}

.memoji {
    min-width: 12rem;
    max-width: 12rem;
    height: 100%;
}

.memoji img {
    width: 100%;
    height: 100%;
}