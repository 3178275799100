.blog-summary {
    border-bottom: 1px solid #ddd;
    padding: 1rem 0;
}

.blog-summary h2 {
    margin: 0;
}

.blog-summary time {
    display: block;
    font-size: 0.9rem;
    color: #555;
}

.blog-summary p {
    margin: 0.5rem 0;
}

.blog-summary a {
    color: #007bff;
    text-decoration: none;
}

.blog-summary a:hover {
    text-decoration: underline;
}
