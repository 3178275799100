footer {
    text-align: center;
    padding: 1rem;
    /*position: fixed;*/
    bottom: 0;
    height: 1%;
    width: 100%;
}

footer p {
    margin: 0;
    font-size: .75rem;
    color: grey;
}
